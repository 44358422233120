import * as React from "react";
import BigTile from "../atoms/BigTile";
import {Box, Stack, Typography} from "@mui/material";

const Products = (props) => {
    return <>
        {props.products?.length  !== 0 ?
            <>
                <Stack direction="column" spacing={4} sx={{
                    justifyContent: 'center',
                    px:{lg: 24},
                    py:10
                }}>
                    <Typography variant='h3' component='h2' color='primary.light' align="center">About Us</Typography>
                    <Typography variant='h4' component='h2' color='primary.light' align="center">
                    Manrose is a leading manufacturer of domestic ventilation and bathroom heating products.  Our innovative and quality designs are the result of listening to our customers and understanding their needs.  We embrace emerging bathroom trends and adapt our technology to provide Australian homes with the very best in ventilation and bathroom comfort.


                    </Typography>
                </Stack>
                {
                    props.products.map((product, index) => {
                        return <BigTile
                            key={'bigtile-products-'+ index}
                            name={product.name}
                            description={product.description}
                            price={product.price}
                            link={product.link}
                            image={props.images[index]?.node?.fluid?.src}
                        />
                    })}
            </>
            : undefined
        }
    </>;
}

export default Products