import {Box, Container, Grid, Stack, Typography} from "@mui/material";
import * as React from "react";
import LeadButton from "./LeadButton";
import icon from '../../images/logo.png'

const Header = (props) => {
    return <Box sx={{
        background:
            props.coverImage?.node?.fluid?.src ?
                'linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, .7) ), url(' + props.coverImage?.node?.fluid?.src + ')'
                :
                'block',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        display: {md: 'flex'},
        justifyContent: 'center',
        backgroundColor: 'background.main',
        color: props.coverImage?.node?.fluid?.src ? 'secondary.main' : 'primary.main',
    }}>
        <Container sx={{
            py: 4,
            maxHeight: "700px"
        }}>
                <Stack direction={'row'} spacing={2}
                       alignItems="center"
                >
                    {props.brand.logo &&
                        <Box display="flex"
                             alignItems="center"
                             justifyContent="center"
                             p={1}
                             borderRadius={100}
                        >
                            <img
                                src={icon}
                                alt={props.brand.name}
                                height={'28px'}
                            />
                        </Box>
                    }
                    <Typography
                        variant='h5'
                    >
                        {props.brand.name}
                    </Typography>
                </Stack>
                <Stack sx={{pt:{xs:12,sm:10}, pb:4, px:{lg:8}}} spacing={2}>
                    <Typography
                        variant='h1'
                        textAlign={'center'}
                    >
                        {props.brand.briefDescription}
                    </Typography>
                   
                        <Typography
                        variant='h4'
                        textAlign={'center'}
                        fontWeight={'medium'}
                        color={props.coverImage?.node?.fluid?.src ? 'secondary.light' : 'primary.light'}
                        sx={{px:{md:7,lg:8}}}
                    >
                                {props.brand.longDescription}
                            
                            </Typography>
<Box sx={{ display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    pt: 4}}>

<LeadButton website={props.website}
                                color={props.coverImage?.node?.fluid?.src ? 'secondary' : 'primary'}/>
</Box>

                </Stack>
        </Container>
    </Box>
}

export default Header