import {Box, Link, Typography} from "@mui/material";
import * as React from "react";


const MadeWith = (props) => {
    return <div style={{width: "100%"}}>
        <Box sx={{
            margin: 'auto',
            color: 'secondary.main',
            backgroundColor: 'primary.main',
            p: 4,
        }}>
            <Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: "center",
                    textAlign: 'center',
                    mb: 1
                }}>
                    <Box  sx={{
                    width: '150px', textAlign: 'left'}}>
                    <Typography variant={'subtitle2'}>Manrose © 2022</Typography>
                    </Box> 
                    <Typography variant={'subtitle1'}
                    >
                        Made with <Link href={'https://tryevergrow.com'}
                                        target="_blank"
                                        rel="noopener"
                                        fontWeight="bold"
                                        underline="none"
                                        color="inherit"
                    >Evergrow</Link>
                    </Typography>

                    <Box  sx={{
                    width: '150px'}}></Box>
                </Box>
            </Box>
        </Box>
    </div>
}

export default MadeWith