import {Box, Typography, Button, Stack} from "@mui/material";
import * as React from "react";
import RightIcon from '@mui/icons-material/KeyboardArrowRightRounded';

const BigTile = (props) => {
    return <Box sx={{mb: 4,
        padding: 4,
        backgroundColor: 'background.light',
        borderRadius: 4,
        minHeight: '25vh',
        alignItems: "center"
    }}>
        <Box sx={{
            pb: 2
        }}>
            <Box>
                <Typography
                    variant='h3'
                >
                    {props.name}
                </Typography>
                {props.price ?
                    <Typography variant='h6' fontWeight='bold' color="primary.light">
                        {props.price}
                    </Typography> : null}
            </Box>
            <Typography
                variant='body1' component='p' fontWeight={400}
            >
                {props.description}
            </Typography>
            {props.link? <Button
                    key={'button'}
                    size="large"
                    sx={{
                        fontWeight: "bold",
                        px:0,
                        "&:hover": { backgroundColor: "transparent", color:"#00A94E" } 
                    }}
                    onClick={() => {
                        window.open(props.link, '_blank');
                    }}
                    endIcon={<RightIcon />}
                    variant="text"

                >
                    View more
                </Button>: null}
        </Box>
        {props.image ? <>
            <Box sx={{
                display: {xs: 'flex', sm: 'flex', md: 'none'},
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Box>
                    <img
                        src={props.image}
                        alt={props.name}
                        width="100%"
                    />
                </Box>
            </Box>
            <Box sx={{display: {xs: 'none', md: 'block'}}}>
                <img
                    src={props.image}
                    alt={props.name}
                    width="100%"
                />
            </Box>
        </> : null}
    </Box>
}

export default BigTile